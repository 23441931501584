import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FilterService } from '../../../core/+state/filter.service';

@Component({
  selector: 'locumsnest-common',
  template: '',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonComponent {
  constructor(private filterService: FilterService) {}

  ngOnInit() {
    this.filterService.initializeFilters();
  }
}
